import {get, post} from "./http"

function PartialPaymentAPI() {
	function pollConfirmation(paymentID: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const intervalId = setInterval(() => {
				get(`/app/partial-payments-status/${paymentID}`)
					.then((result: any) => {
						if (result.Status !== "Initiated") {
							clearInterval(intervalId)
							resolve(result)
						}
					})
					.catch((e) => {
						clearInterval(intervalId)
						reject(e)
					})
			}, 1000)
		})
	}

	function createWithCustomAmount({secureCode, paymentMethod, affordabilityAssessmentID, paymentAmount}) {
		return post(`/app/partial-payments/${secureCode}`, {
			PaymentCreateRequest: {
				PaymentMethod: paymentMethod,
				AffordabilityAssessmentID: affordabilityAssessmentID,
			},
			Amount: paymentAmount,
		})
	}

	function getAmountPage({secureCode}) {
		return get(`/app/partial-payments/${secureCode}/amount`)
	}

	function getPaymentMethodPage({secureCode}) {
		return get(`/app/partial-payments/${secureCode}/payment-methods`)
	}

	return {pollConfirmation, createWithCustomAmount, getAmountPage, getPaymentMethodPage}
}

export {PartialPaymentAPI}
