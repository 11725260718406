import {CardType} from "@indebted/components/AcceptedCards"

import {get, post} from "./http"

type ProviderDataType = {
	SetupIntentID: string
	PublishableKey: string
	ClientSecret: string
}

type DebitCardFormI18nType = {
	Header: string
	Title: string
	FormFullNameLabel: string
	FormEmailLabel: string
	FormEmailHelp: string
	FormErrorsEmailNameValidation: string
	FormErrorsFundingType: string
	FormTermsAndConditionsText: string
	FormTermsAndConditionsLink: string
	FormTermsAndConditionsLinkText: string
	FormButtonLabel: string
	FormPaymentMethodSelectionText: string
	FormPaymentMethodSelectionLinkText: string
	PaymentNotSupportedError: string
}

type PaymentFormPageResponse = {
	Locale: string
	HidePostalCode: boolean
	AcceptedCards: CardType[]
	MorePaymentMethodsAvailable: boolean
	PaymentArrangementID: string
	PaymentNotSupportedError: string
	I18n: DebitCardFormI18nType // TODO add more I18n Types
	ProviderData: ProviderDataType
	PaymentMethod: string
}

function PaymentArrangementAPI() {
	async function initiateSetup({secureCode}): Promise<{Next: string; PaymentArrangementID: string}> {
		const data = await post(`/app/payment-arrangement/${secureCode}`, {})
		if (
			data.Next == "" ||
			data.Next == undefined ||
			data.PaymentArrangementID == undefined ||
			data.PaymentArrangementID == ""
		) {
			throw {Message: "An error occurred. Please try again."}
		}

		return data
	}

	async function getConfigureFrequencyPage({paymentArrangementID}) {
		return await get(`/app/payment-arrangements/${paymentArrangementID}/configure-frequency`)
	}

	async function configureFrequency({paymentArrangementID, frequency}) {
		const data = await post(`/app/payment-arrangements/${paymentArrangementID}/configure-frequency`, {
			Frequency: frequency,
		})

		if (data.Next === undefined || data.Next === "") {
			throw {Message: "An error occurred. Please try again."}
		}

		return data
	}

	async function getConfigureStartDatePage({paymentArrangementID}) {
		return await get(`/app/payment-arrangements/${paymentArrangementID}/configure-start-date`)
	}

	async function configureStartDate({paymentArrangementID, startDate}) {
		return await post(`/app/payment-arrangements/${paymentArrangementID}/configure-start-date`, {
			StartDate: startDate,
		})
	}

	async function getConfigureInstalmentAmountPage({paymentArrangementID}) {
		return await get(`/app/payment-arrangements/${paymentArrangementID}/configure-instalment-amount`)
	}

	async function configureInstalmentAmount({paymentArrangementID, instalmentAmount}) {
		const data = await post(`/app/payment-arrangements/${paymentArrangementID}/configure-instalment-amount`, {
			InstalmentAmount: instalmentAmount,
		})

		if (data.Next === undefined || data.Next === "") {
			throw {Message: "An error occurred. Please try again."}
		}

		return data
	}

	async function getPaymentFormPage({paymentArrangementID}): Promise<PaymentFormPageResponse> {
		return await get(`/app/payment-arrangements/${paymentArrangementID}/payment-form`)
	}

	async function receiveSetupSubmitted({
		PaymentArrangementID,
		ProviderName,
		ProviderRawEvent,
		ProviderSetupID,
		SubmittedAt,
	}) {
		return await post(`/app/payment-arrangements/${PaymentArrangementID}/receive-setup-submitted`, {
			ProviderName: ProviderName,
			ProviderRawEvent: ProviderRawEvent,
			ProviderSetupID: ProviderSetupID,
			SubmittedAt: SubmittedAt,
		})
	}

	const pollConfirmation = async (paymentArrangementID: string, numberOfRetries: number = 0) => {
		const result = await get(`/app/payment-arrangements/${paymentArrangementID}/status`)
		if (result.Status !== "Initiated" && result.Status != "Submitted") {
			return result
		}

		if (numberOfRetries === 59) {
			return result
		}

		await waitForOneSecond()
		return await pollConfirmation(paymentArrangementID, numberOfRetries + 1)
	}

	function waitForOneSecond() {
		return new Promise((resolve) => setTimeout(resolve, 1000))
	}

	async function getManagePage({paymentArrangementID}) {
		return await get(`/app/payment-arrangements/${paymentArrangementID}/manage`)
	}

	return {
		initiateSetup,
		getConfigureFrequencyPage,
		configureFrequency,
		getConfigureStartDatePage,
		configureStartDate,
		getConfigureInstalmentAmountPage,
		configureInstalmentAmount,
		getPaymentFormPage,
		receiveSetupSubmitted,
		pollConfirmation,
		getManagePage,
	}
}

export {PaymentArrangementAPI, PaymentFormPageResponse, DebitCardFormI18nType}
