import {regionManager} from "@indebted/api"

declare global {
	interface Window {
		regionManager: any
		FrontChat: any
	}
}

window.regionManager = regionManager
;(function () {
	const indebtedLegacyPlatform = /^\/([A-Z0-9]{4})-([A-Z0-9]{4})$/g // ABCD-1234
	const indebtedPlatform = /^\/[01]-[A-Z0-9]{4}-[A-Z0-9]{4}$/g // 0-ABCD-1234

	// eslint-disable-next-line no-restricted-globals
	const match = location.pathname.match(indebtedLegacyPlatform) || location.pathname.match(indebtedPlatform)
	if (!match) {
		return
	}

	const secureCode = match[0].replace("/", "")
	window.regionManager.setRegionFromSecureCode(secureCode)

	fetch(window.regionManager.url(`/app/metadata/${secureCode}`), {})
		.then((response) => response.json())
		.then(({EnableFrontChat, FrontLiveChatID}) => {
			const chatBundleUrl = "https://chat-assets.frontapp.com/v1/chat.bundle.js"

			return new Promise((resolve, reject) => {
				const script = document.createElement("script")
				script.src = chatBundleUrl
				script.async = true
				script.onload = resolve
				script.onerror = reject
				document.body.appendChild(script)
			}).then(() => {
				if (EnableFrontChat) {
					window.FrontChat("init", {chatId: FrontLiveChatID, useDefaultLauncher: true})
				}
			})
		})
		.catch((error) => {
			console.warn("Failed loading FrontChat:", error)
		})
})()
