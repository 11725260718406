import {getRegion, Regions} from "./regionManager"

function get(endpoint: string, options = {}) {
	return req(endpoint, {...options, method: "GET"})
}

function post(endpoint: string, body?: any, options = {}) {
	return req(endpoint, {...options, method: "POST", body: JSON.stringify(body)})
}

function put(endpoint: string, body?: any, options = {}) {
	return req(endpoint, {...options, method: "PUT", body: JSON.stringify(body)})
}

function req(endpoint: string, options: RequestInit): Promise<any> {
	return new Promise((resolve, reject) => {
		fetch(url(endpoint), options)
			.then((res) => {
				const contentType = res.headers.get("Content-Type")
				const isJSON = contentType && contentType.includes("application/json")
				const promise = isJSON && res.status !== 204 ? res.json() : res.text()
				promise.then((body) => (res.status >= 400 ? reject(apiError(body, res.status)) : resolve(body)))
			})
			.catch(() => {
				reject(apiError("Network error", 500))
			})
	})
}

function url(endpoint: string) {
	const region = getRegion()

	if (region === Regions.US.name()) {
		return usUrl(endpoint)
	} else if (region === Regions.AE.name()) {
		return aeUrl(endpoint)
	} else {
		return auUrl(endpoint)
	}
}

function usUrl(endpoint: string) {
	if (process.env.API_URL_US == undefined) {
		throw new Error("API_URL_US is not set")
	}
	return `${process.env.API_URL_US}${endpoint}`
}

function auUrl(endpoint: string) {
	if (process.env.API_URL_AU == undefined) {
		throw new Error("API_URL_AU is not set")
	}
	return `${process.env.API_URL_AU}${endpoint}`
}

function aeUrl(endpoint: string) {
	if (process.env.API_URL_AE == undefined) {
		throw new Error("API_URL_AE is not set")
	}
	return `${process.env.API_URL_AE}${endpoint}`
}

function apiError(error, status = 500) {
	const result = {
		Message: "Unknown error",
		Status: status,
		i18n: error?.I18n,
		error: error,
	}

	if (!error) return result

	if (typeof error == "string") {
		result.Message = error
	} else if (error.message) {
		result.Message = error.message
	} else if (error.Message) {
		result.Message = error.Message
	} else if (error.Errors) {
		const message = Object.keys(error.Errors)
			.map((key) => {
				return error.Errors[key].map((msg) => `${key} ${msg}`)
			})
			.join(".")
		result.Message = message
	}

	return result
}

export {get, post, put}
