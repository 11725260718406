import {get, post} from "./http"

function PaymentAPI() {
	function pollConfirmation(paymentID: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const intervalId = setInterval(() => {
				get(`/app/payments-status/${paymentID}`)
					.then((result: any) => {
						if (result.Status !== "Initiated") {
							clearInterval(intervalId)
							resolve(result)
						}
					})
					.catch((e) => {
						clearInterval(intervalId)
						reject(e)
					})
			}, 1000)
		})
	}

	function create({secureCode, paymentMethod, affordabilityAssessmentID}) {
		return post(`/app/payments/${secureCode}`, {
			PaymentMethod: paymentMethod,
			AffordabilityAssessmentID: affordabilityAssessmentID,
		})
	}

	function createForm({secureCode}) {
		return get(`/app/payments/${secureCode}`)
	}

	function getDefaultPaymentMethod({secureCode}) {
		return get(`/app/payments/${secureCode}/default-payment-method`)
	}

	return {pollConfirmation, create, createForm, getDefaultPaymentMethod}
}

export {PaymentAPI}
